<template>
  <div id="Joe">
<!--    <my-footer/>-->
    <div class="footer-title"></div>
<!--        <headers/>-->
<!--    <Printer/>-->
<!--    <home/>-->
    <div class="HeaderImg" style="background: url('https://s1.ax1x.com/2023/05/14/p9cgK0g.jpg') center;background-size:cover;">
      <div class="infomation">
        <div class="title">{{$store.state.webInfo.webName}}</div>
        <div class="desctitle">
          <span class="motto joe_motto">
            {{printerInfo}}
          </span></div>
      </div>
      <section class="HeaderImg_bottom">
        <svg class="waves-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
          <defs>
            <path id="gentle-wave" d="M -160 44 c 30 0 58 -18 88 -18 s 58 18 88 18 s 58 -18 88 -18 s 58 18 88 18 v 44 h -352 Z"></path>
          </defs>
          <g class="parallax">
            <use xlink:href="#gentle-wave" x="48" y="0"></use>
            <use xlink:href="#gentle-wave" x="48" y="3"></use>
            <use xlink:href="#gentle-wave" x="48" y="5"></use>
            <use xlink:href="#gentle-wave" x="48" y="7"></use>
          </g>
        </svg>
      </section>
    </div>

    <div class="joe_container">
      <div class="joe_main">
        <div class="joe_index">
          <div class="joe_index__list" data-wow="fadeInUp" v-if="articles.length==0 || articles==undefined">
            <ul class="joe_list"></ul>
            <ul class="joe_list__loading" style="">
              <li class="item">
                <div class="thumbnail"></div>
                <div class="information">
                  <div class="title"></div>
                  <div class="abstract">
                  </div>
                </div>
              </li>
              <li class="item">
                <div class="thumbnail"></div>
                <div class="information">
                  <div class="title"></div>
                  <div class="abstract">
                    <p></p>
                    <p></p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <ul class="joe_list" v-else>
            <li class="joe_list__item wow default"  v-for="article in articles" :key="article.id" >
              <div class="line"></div>
              <a @click="$router.push({path: '/article', query: {id: article.id}})" class="thumbnail" :title="article.articleTitle" target="_blank" rel="noopener noreferrer">
                <img class=" ls-is-cached lazyloaded" :src="article.articleCover" :data-src="article.articleCover" width="100%" height="100%">
                <time :datetime="article.updateTime">{{article.updateTime}}</time>
              </a>
              <div class="information">
                <a @click="$router.push({path: '/article', query: {id: article.id}})" class="title" :title="articles.articleTitle" target="_blank" rel="noopener noreferrer">
                  <span v-if="article.recommendStatus" class="badge" style="display: inline-block">推荐</span>{{article.articleTitle}}
                </a>
                <a class="abstract" @click="$router.push({path: '/article', query: {id: article.id}})" title="文章摘要" target="_blank" rel="noopener noreferrer">
                  {{article.articleContent}}
                </a>
                <div class="meta">
                  <ul class="items">
                    <li>{{article.updateTime}}</li>
                    <li>阅读{{article.viewCount}} </li>
                    <li>{{article.commentCount}}评论</li>
                    <li>{{article.likeCount}}点赞</li>
                  </ul>
                  <div class="last" style="display: flex">
                    <svg class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M512.2 564.743a76.818 76.818 0 0 1-30.973-6.508L108.224 393.877c-26.105-11.508-42.56-35.755-42.927-63.272-.384-27.44 15.356-52.053 41.042-64.232l373.004-176.74c20.591-9.737 45.16-9.755 65.75.017L917.68 266.39c25.668 12.188 41.39 36.792 41.024 64.231-.384 27.5-16.821 51.73-42.908 63.237l-372.57 164.377a77.18 77.18 0 0 1-31.025 6.508zM139.843 329.592l370.213 163.241c1.291.56 3.018.567 4.345-.009l369.758-163.128-369.706-175.464v-.01c-1.326-.628-3.158-.636-4.502 0l-370.108 175.37zm748.015 1.858h.175-.175zM512.376 941.674c-10.348 0-20.8-2.32-30.537-6.997L121.05 778.624c-18.113-7.834-26.454-28.87-18.62-46.983 7.835-18.112 28.862-26.488 46.993-18.61l362.08 156.629 345.26-156.366c17.939-8.166 39.14-.253 47.324 17.746 8.166 17.964.227 39.157-17.729 47.324l-344.51 156.61c-9.196 4.449-19.281 6.7-29.471 6.7z" fill="#444"></path><path d="M871.563 515.449L511.81 671.775 152.358 515.787v73.578a34.248 34.248 0 0 0 20.76 31.48l301.518 129.19c11.806 5.703 24.499 8.546 37.175 8.546s25.367-2.843 37.174-8.546L850.82 620.534a34.248 34.248 0 0 0 20.744-31.474V515.45z" fill="#ff6a18"></path></svg>
                    <a class="link" target="_blank" rel="noopener noreferrer" @click="$router.push({path: '/article', query: {id: article.id}})">推荐</a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="joe_load theme--light">
          <el-pagination background layout="prev, pager, next"
                         :current-page="pagination.current"
                         :page-size="pagination.size"
                         :total="pagination.total"
                         @current-change="handlePageChange">
          </el-pagination>
        </div>
      </div>
      <cebianlan ref="cebianlan"></cebianlan>
    </div>
    <my-footer/>

  </div>
</template>
<script>
  //轮播图
  const carousel =()=>import("./common/carousel")
  //joe的导航栏
  const headers =()=> import("./headers")

  const myFooter = () => import( "./common/myFooter");
  import cebianlan from './ceBianLan.vue';
  export default {
    components: {

      myFooter,
      cebianlan,

    },

    data() {
      return {
        loading: false,
        showAside: true,
        printerInfo: "你看对面的青山多漂亮",
        pagination: {
          current: 1,
          size: 10,
          total: 0,
          searchKey: "",
          sortId: null
        },
        guShi: {
          "content": "",
          "origin": "",
          "author": "",
          "category": ""
        },
        articles: []
      };
    },

    watch: {},

    created() {
      // this.getGuShi();
      this.getArticles();
    },

    mounted() {
    },

    methods: {
      initCebianlan() {
        this.$nextTick(() => {
          this.$refs.cebianlan.init(this.pagination.total,this.pagination.current);
        });
      },
      initGame(){
        this.$nextTick(() => {
          this.$refs.cebianlan.init(this.pagination.total,this.pagination.current);
        });
      },
      async selectSort(sort) {
        this.pagination = {
          current: 1,
          size: 10,
          total: 0,
          searchKey: "",
          sortId: sort.id
        };
        this.articles = [];
        await this.getArticles();
        this.$nextTick(() => {
          document.querySelector('.recent-posts').scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
          });
        });
      },
      pageArticles() {
        this.pagination.current = this.pagination.current + 1;
        this.getArticles();
      },
      handlePageChange(val) {
        this.articles = [];
        this.pagination.current = val;
        this.getArticles();
      },
      async getArticles() {
        await this.$http.post(this.$constant.baseURL + "/article/listArticle", this.pagination)
          .then((res) => {
            if (!this.$common.isEmpty(res.data)) {
              this.articles = this.articles.concat(res.data.records);
              this.pagination.total = res.data.total;
              this.initCebianlan();
            }
          })
          .catch((error) => {
            this.$message({
              message: error.message,
              type: "error"
            });
          });
      },
      navigation(selector) {
        let pageId = document.querySelector(selector);
        window.scrollTo({
          top: pageId.offsetTop,
          behavior: "smooth"
        });
      },
      getGuShi() {
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('get', this.$constant.jinrishici);
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            that.guShi = JSON.parse(xhr.responseText);
            that.printerInfo = that.guShi.hitokoto;
          }
        };
        xhr.send();
      }
    }
  }
</script>

<style scoped>
.footer-title {
  padding-top: 10px;
  font-size: 16px;
}
</style>
