<template>
  <div >
    <el-row class="joe_aside__item-title" align="center">
      <el-col :span="24">
<!--        <div class="joe_aside__item-title-text">水文目录</div>-->
      </el-col>
    </el-row>
    <div v-for="(item, index) in catalog" :key="index">
      <el-row style="padding-top: 1em;padding-bottom: 1em;" >
        <div class="log-back" style="padding-left: 1em;padding-right: 1em;">
          <div style="font-size: 16px;cursor: pointer" class="log-item">
            <li class="structure active"
              :key="index"
              :style="{ paddingLeft: item.level * 30 - 44 + 'px'}"
              :class="{'cc':item.read == true}">
            <a :href="'#' + item.id" :id="item.id">{{ item.title }}</a>
            </li>
          </div>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "toc",
  data() {
    return {
      tocData: [],
      catalog: [],
    }
  },
  mounted() {
    this.getReading()
  },
  methods: {
    init(data) {
      this.catalog = data
    },
    getReading() {
      //获取页面滚动值
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      //遍历小标题
      for (let i = 0; i < this.catalog.length; i++) {
        //获取小标题距离屏幕顶端的距离
        var offsetTop = document.querySelector("#" + this.catalog[i].id).offsetTop;
        //根据条件做出判断，我这里是当小标题和屏幕顶端的距离小于300且没有被标明在读时，就将其背景颜色改变。
        if ((offsetTop - scrollTop) < 300 && (offsetTop - scrollTop) > 0 && this.catalog[i].read == false) {
          this.catalog[i].read = true
          //再将其他小标题背景色改成未读的背景色
          for (let j = 0; j < this.catalog.length; j++) {
            if (j != i) {
              this.catalog[j].read = false
            }
          }
        }
      }
    }
  }

}
</script>

<style scoped>
.cc {
  background-color: #f5f5f5;
}
</style>
